import $ from 'jquery';
import React, { useRef, useEffect, useState } from 'react'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Legenda from '../components/Legenda.js'
import L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet'
import RssFeedRoundedIcon from '@material-ui/icons/RssFeedRounded'
import ApiPrevmet from '../services/Prevmet.js'
import JsonEstados from '../assets/json/estados.json'

import './css/Style.css'
import './css/Mobile.css'
import './css/HD-Screen.css'
import 'leaflet/dist/leaflet.css'

const Index = () => {
  const estados = {
    'Acre'                : 'AC',
    'Alagoas'             : 'AL',
    'Amapá'               : 'AP',
    'Amazonas'            : 'AM',
    'Bahia'               : 'BA',
    'Ceará'               : 'CE',
    'Distrito Federal'    : 'DF',
    'Espírito Santo'      : 'ES',
    'Goiás'               : 'GO',
    'Maranhão'            : 'MA',
    'Mato Grosso'         : 'MT',
    'Mato Grosso do Sul'  : 'MS',
    'Minas Gerais'        : 'MG',
    'Pará'                : 'PA',
    'Paraíba'             : 'PB',
    'Paraná'              : 'PR',
    'Pernambuco'          : 'PE',
    'Piauí'               : 'PI',
    'Rio de Janeiro'      : 'RJ',
    'Rio Grande do Norte' : 'RN',
    'Rio Grande do Sul'   : 'RS',
    'Rondônia'            : 'RO',
    'Roraima'             : 'RR',
    'Santa Catarina'      : 'SC',
    'São Paulo'           : 'SP',
    'Sergipe'             : 'SE',
    'Tocantins'           : 'TO'
  }

  const mapa = useRef(React.createRef)

  const [qtdeHoje, setQtdeHoje] = useState(0)
  const [qtdeFuturo, setQtdeFuturo] = useState(0)
  const [avisosHoje, setAvisosHoje] = useState(null)
  const [iconesHoje, setIconesHoje] = useState(null)
  const [avisosFuturo, setAvisosFuturo] = useState(null)
  const [iconesFuturo, setIconesFuturo] = useState(null)
  const [tipoAviso, setTipoAviso] = useState(false)

  const hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const getAvisos = async () => {
    await ApiPrevmet.get('/avisos/ativos')
    .then(res => {
      let hoje = []
      let icones_hoje = []
      let futuro = []
      let icones_futuro = []

      for(let i = 0; i < res.data.hoje.length; i++) {
        for(let j = 0; j < res.data.hoje[i].estados.split(',').length; j++) {
          let div_id = `#alertas-${estados[res.data.hoje[i].estados.split(',')[j]]}-hoje`
          let path_img = require(`../assets/Placas/Desenho/${res.data.hoje[i].descricao.replace(/ /g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '')}.png`)
          let cor = hexToRgb(res.data.hoje[i].aviso_cor)

          $(div_id).append(`
            <div class="img-tooltip">
              <span style="background-color: rgba(${cor.r}, ${cor.g}, ${cor.b}, 0.75); color: black; font-weight: bold;">${res.data.hoje[i].descricao} - ${res.data.hoje[i].severidade}</span>
              <div class="background-img-severidade" style="background-color: rgba(${cor.r}, ${cor.g}, ${cor.b}, 0.75)">
                <a href="/${res.data.hoje[i].id}">
                  <img
                    class="img-severidade"
                    alt="${res.data.hoje[i].descricao} - ${res.data.hoje[i].severidade}"
                    src="${path_img}"
                  />
                </a>
              </div>
            </div>
          `)
        }

        L.geoJson(JSON.parse(res.data.hoje[i].poligono), {
          onEachFeature: function (feature, layer) {
            layer.options.fillColor = res.data.hoje[i].aviso_cor
            layer.options.fillOpacity = 0.7
            layer.options.color = res.data.hoje[i].aviso_cor
            layer.options.opacity = 1
            layer.options.weight = 1
            hoje.push(layer)

            icones_hoje.push(
              L.marker(
                [layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
                  icon: L.icon({
                    iconUrl     : res.data.hoje[i].icone,
                    iconAnchor  :   [17, 50],
                    popupAnchor :  [2, -40]
                  })
            }).bindPopup(`<b>${res.data.hoje[i].descricao}</b><br />Para mais informações <a href="/${res.data.hoje[i].id}">clique aqui</a>.`))
          }
        })
      }

      for(let i = 0; i < res.data.futuro.length; i++) {
        for(let j = 0; j < res.data.futuro[i].estados.split(',').length; j++) {
          let div_id = `#alertas-${estados[res.data.futuro[i].estados.split(',')[j]]}-futuro`
          let path_img = require(`../assets/Placas/Desenho/${res.data.futuro[i].descricao.replace(/ /g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '')}.png`)
          let cor = hexToRgb(res.data.futuro[i].aviso_cor)

          $(div_id).append(`
            <div class="img-tooltip">
              <span style="background-color: rgba(${cor.r}, ${cor.g}, ${cor.b}, 0.85); color: black; font-weight: bold;">${res.data.futuro[i].descricao} - ${res.data.futuro[i].severidade}</span>
                <div class="background-img-severidade" style="background-color: rgba(${cor.r}, ${cor.g}, ${cor.b}, 0.8)">
                  <a href="/${res.data.futuro[i].id}">
                    <img
                      class="img-severidade"
                      alt="${res.data.futuro[i].descricao} - ${res.data.futuro[i].severidade}"
                      src="${path_img}"
                    />
                  </a>
                </div>
            </div>
          `)
        }

        L.geoJson(JSON.parse(res.data.futuro[i].poligono), {
          onEachFeature: function (feature, layer) {
            layer.options.fillColor = res.data.futuro[i].aviso_cor
            layer.options.fillOpacity = 0.7
            layer.options.color = res.data.futuro[i].aviso_cor
            layer.options.opacity = 1
            layer.options.weight = 1
            futuro.push(layer)

            icones_futuro.push(
              L.marker(
                [layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
                  icon: L.icon({
                    iconUrl     : res.data.futuro[i].icone,
                    iconAnchor  :   [17, 50],
                    popupAnchor :  [2, -40]
                  })
            }).bindPopup(`<b>${res.data.futuro[i].descricao}</b><br />Para mais informações <a href="/${res.data.futuro[i].id}">clique aqui</a>.`))
          }
        })
      }
      setQtdeHoje(hoje.length)
      setQtdeFuturo(futuro.length)

      setIconesHoje(L.layerGroup(icones_hoje))
      setIconesFuturo(L.layerGroup(icones_futuro))

      setAvisosHoje(L.layerGroup(hoje))
      setAvisosFuturo(L.layerGroup(futuro))
    })
    .catch((err) => {
      console.log(err)
      alert('Erro ao carregar avisos, tente novamente. ')
    })
  }

  const toogleDivsEstados = (tipo) => {
    // eslint-disable-next-line no-unused-vars
    for(const estado in estados) {
      const id_hoje = `#alertas-${estados[estado]}-hoje`
      const id_futuro = `#alertas-${estados[estado]}-futuro`
      if(tipo === 'hoje') {
        $(id_hoje).css('display', 'flex')
        $(id_futuro).css('display', 'none')
      } else {
        $(id_futuro).css('display', 'flex')
        $(id_hoje).css('display', 'none')
      }
    }
  }

  useEffect(() => {
    getAvisos()
    mapa.current.leafletElement.addLayer(L.geoJson(JsonEstados, {
      color: 'black',
      fillColor: 'green',
      fillOpacity: 1,
      weight: 0.5
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(avisosHoje !== null) {
      toogleDivsEstados('hoje')
      mapa.current.leafletElement.addLayer(avisosHoje)
      mapa.current.leafletElement.addLayer(iconesHoje)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avisosHoje])

  useEffect(() => {
    if(tipoAviso && avisosFuturo !== null) {
      toogleDivsEstados('futuro')
      mapa.current.leafletElement.addLayer(avisosFuturo)
      mapa.current.leafletElement.addLayer(iconesFuturo)

      mapa.current.leafletElement.removeLayer(avisosHoje)
      mapa.current.leafletElement.removeLayer(iconesHoje)
    } else if(!tipoAviso && avisosHoje !== null) {
      toogleDivsEstados('hoje')
      mapa.current.leafletElement.addLayer(avisosHoje)
      mapa.current.leafletElement.addLayer(iconesHoje)

      mapa.current.leafletElement.removeLayer(avisosFuturo)
      mapa.current.leafletElement.removeLayer(iconesFuturo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoAviso])

  return (
    <div className="App">
      <Header />
      <div className="container-geral">

        <div className="container-mapa">
          <Map
            ref={mapa}
            className={'map'}
            style={{ height: '600px', width: '100%', borderRadius: 15 }}
            zoom={3}
            scrollWheelZoom={false}
            minZoom={4}
            center={[-15.6014, -59.597]}
            preferCanvas={'true'}
            doubleClickZoom={false}
            zoomControl={true}
            maxBounds={[[7.23542, -95.332414], [-35.67909, -16.362345]]}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
          </Map>
        </div>

        <div className="container-estados">
          <div className="estados-titulo">

            <div style={{ paddingBottom: 50, backgroundColor: 'white' }}>
              <div className="rss-avisos">
                <a href="https://apiprevmet3.inmet.gov.br/avisos/rss" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: 'white' }}>
                  <RssFeedRoundedIcon id="icone-rss" style={{ fontSize: 40, backgroundColor: 'white', color: 'red' }} />
                </a>
              </div>

              <div className="opcoes-avisos">
                <span style={{ fontSize: 20, backgroundColor: 'white', marginRight: 10, fontWeight: 'bold' }}>
                  Hoje [{qtdeHoje}]
                </span>

                <label className="switch">
                  <input type="checkbox" checked={tipoAviso} onChange={() => { setTipoAviso(!tipoAviso) }} />
                  <span className="slider round"></span>
                </label>

                <span style={{ fontSize: 20, backgroundColor: 'white', marginLeft: 10, fontWeight: 'bold' }}>
                  Futuro [{qtdeFuturo}]
                </span>
              </div>

            </div>

            <div className="estados">
              <div className="AC">
                <div className="img-tooltip">
                  <span>Acre</span>
                  <img className="img-bandeira" alt="AC" src={require('../assets/Bandeiras/AC.png')} />
                </div>
                <span className="titulo-bandeira">AC</span>
                <div className="alertas-AC-hoje" id="alertas-AC-hoje"></div>
                <div className="alertas-AC-futuro" id="alertas-AC-futuro"></div>
              </div>

              <div className="AL">
                <div className="img-tooltip">
                  <span>Alagoas</span>
                  <img className="img-bandeira" alt="AL" src={require('../assets/Bandeiras/AL.png')} />
                </div>
                <span className="titulo-bandeira">AL</span>
                <div className="alertas-AL-hoje" id="alertas-AL-hoje"></div>
                <div className="alertas-AL-futuro" id="alertas-AL-futuro"></div>
              </div>

              <div className="AP">
                <div className="img-tooltip">
                  <span>Amapá</span>
                  <img className="img-bandeira" alt="AP" src={require('../assets/Bandeiras/AP.png')} />
                </div>
                <span className="titulo-bandeira">AP</span>
                <div className="alertas-AP-hoje" id="alertas-AP-hoje"></div>
                <div className="alertas-AP-futuro" id="alertas-AP-futuro"></div>
              </div>

              <div className="AM">
                <div className="img-tooltip">
                  <span>Amazonas</span>
                  <img className="img-bandeira" alt="AM" src={require('../assets/Bandeiras/AM.png')} />
                </div>
                <span className="titulo-bandeira">AM</span>
                <div className="alertas-AM-hoje" id="alertas-AM-hoje"></div>
                <div className="alertas-AM-futuro" id="alertas-AM-futuro"></div>
              </div>

              <div className="BA">
                <div className="img-tooltip">
                  <span>Bahia</span>
                  <img className="img-bandeira" alt="BA" src={require('../assets/Bandeiras/BA.png')} />
                </div>
                <span className="titulo-bandeira">BA</span>
                <div className="alertas-BA-hoje" id="alertas-BA-hoje"></div>
                <div className="alertas-BA-futuro" id="alertas-BA-futuro"></div>
              </div>

              <div className="CE">
                <div className="img-tooltip">
                  <span>Ceará</span>
                  <img className="img-bandeira" alt="CE" src={require('../assets/Bandeiras/CE.png')} />
                </div>
                <span className="titulo-bandeira">CE</span><br />
                <div className="alertas-CE-hoje" id="alertas-CE-hoje"></div>
                <div className="alertas-CE-futuro" id="alertas-CE-futuro"></div>
              </div>

              <div className="DF">
                <div className="img-tooltip">
                  <span>Distrito Federal</span>
                  <img className="img-bandeira" alt="DF" src={require('../assets/Bandeiras/DF.png')} />
                </div>
                <span className="titulo-bandeira">DF</span><br />
                <div className="alertas-DF-hoje" id="alertas-DF-hoje"></div>
                <div className="alertas-DF-futuro" id="alertas-DF-futuro"></div>
              </div>

              <div className="ES">
                <div className="img-tooltip">
                  <span>Espírito Santo</span>
                  <img className="img-bandeira" alt="ES" src={require('../assets/Bandeiras/ES.png')} />
                </div>
                <span className="titulo-bandeira">ES</span>
                <div className="alertas-ES-hoje" id="alertas-ES-hoje"></div>
                <div className="alertas-ES-futuro" id="alertas-ES-futuro"></div>
              </div>

              <div className="GO">
                <div className="img-tooltip">
                  <span>Goiás</span>
                  <img className="img-bandeira" alt="GO" src={require('../assets/Bandeiras/GO.png')} />
                </div>
                <span className="titulo-bandeira">GO</span>
                <div className="alertas-GO-hoje" id="alertas-GO-hoje"></div>
                <div className="alertas-GO-futuro" id="alertas-GO-futuro"></div>
              </div>

              <div className="MA">
                <div className="img-tooltip">
                  <span>Maranhão</span>
                  <img className="img-bandeira" alt="MA" src={require('../assets/Bandeiras/MA.png')} />
                </div>
                <span className="titulo-bandeira">MA</span>
                <div className="alertas-MA-hoje" id="alertas-MA-hoje"></div>
                <div className="alertas-MA-futuro" id="alertas-MA-futuro"></div>
              </div>

              <div className="MT">
                <div className="img-tooltip">
                  <span>Mato Grosso</span>
                  <img className="img-bandeira" alt="MT" src={require('../assets/Bandeiras/MT.png')} />
                </div>
                <span className="titulo-bandeira">MT</span>
                <div className="alertas-MT-hoje" id="alertas-MT-hoje"></div>
                <div className="alertas-MT-futuro" id="alertas-MT-futuro"></div>
              </div>

              <div className="MS">
                <div className="img-tooltip">
                  <span>Mato Grosso do Sul</span>
                  <img className="img-bandeira" alt="MS" src={require('../assets/Bandeiras/MS.png')} />
                </div>
                <span className="titulo-bandeira">MS</span>
                <div className="alertas-MS-hoje" id="alertas-MS-hoje"></div>
                <div className="alertas-MS-futuro" id="alertas-MS-futuro"></div>
              </div>

              <div className="MG">
                <div className="img-tooltip">
                  <span>Minas Gerais</span>
                  <img className="img-bandeira" alt="MG" src={require('../assets/Bandeiras/MG.png')} />
                </div>
                <span className="titulo-bandeira">MG</span>
                <div className="alertas-MG-hoje" id="alertas-MG-hoje"></div>
                <div className="alertas-MG-futuro" id="alertas-MG-futuro"></div>
              </div>

              <div className="PA">
                <div className="img-tooltip">
                  <span>Pará</span>
                  <img className="img-bandeira" alt="PA" src={require('../assets/Bandeiras/PA.png')} />
                </div>
                <span className="titulo-bandeira">PA</span>
                <div className="alertas-PA-hoje" id="alertas-PA-hoje"></div>
                <div className="alertas-PA-futuro" id="alertas-PA-futuro"></div>
              </div>

              <div className="PB">
                <div className="img-tooltip">
                  <span>Paraíba</span>
                  <img className="img-bandeira" alt="PB" src={require('../assets/Bandeiras/PB.png')} />
                </div>
                <span className="titulo-bandeira">PB</span>
                <div className="alertas-PB-hoje" id="alertas-PB-hoje"></div>
                <div className="alertas-PB-futuro" id="alertas-PB-futuro"></div>
              </div>

              <div className="PR">
                <div className="img-tooltip">
                  <span>Paraná</span>
                  <img className="img-bandeira" alt="PR" src={require('../assets/Bandeiras/PR.png')} />
                </div>
                <span className="titulo-bandeira">PR</span>
                <div className="alertas-PR-hoje" id="alertas-PR-hoje"></div>
                <div className="alertas-PR-futuro" id="alertas-PR-futuro"></div>
              </div>

              <div className="PE">
                <div className="img-tooltip">
                  <span>Pernambuco</span>
                  <img className="img-bandeira" alt="PE" src={require('../assets/Bandeiras/PE.png')} />
                </div>
                <span className="titulo-bandeira">PE</span>
                <div className="alertas-PE-hoje" id="alertas-PE-hoje"></div>
                <div className="alertas-PE-futuro" id="alertas-PE-futuro"></div>
              </div>

              <div className="PI">
                <div className="img-tooltip">
                  <span>Piauí</span>
                  <img className="img-bandeira" alt="PI" src={require('../assets/Bandeiras/PI.png')} />
                </div>
                <span className="titulo-bandeira">PI</span>
                <div className="alertas-PI-hoje" id="alertas-PI-hoje"></div>
                <div className="alertas-PI-futuro" id="alertas-PI-futuro"></div>
              </div>

              <div className="RJ">
                <div className="img-tooltip">
                  <span>Rio de Janeiro</span>
                  <img className="img-bandeira" alt="RJ" src={require('../assets/Bandeiras/RJ.png')} />
                </div>
                <span className="titulo-bandeira">RJ</span>
                <div className="alertas-RJ-hoje" id="alertas-RJ-hoje"></div>
                <div className="alertas-RJ-futuro" id="alertas-RJ-futuro"></div>
              </div>

              <div className="RN">
                <div className="img-tooltip">
                  <span>Rio Grande do Norte</span>
                  <img className="img-bandeira" alt="RN" src={require('../assets/Bandeiras/RN.png')} />
                </div>
                <span className="titulo-bandeira">RN</span>
                <div className="alertas-RN-hoje" id="alertas-RN-hoje"></div>
                <div className="alertas-RN-futuro" id="alertas-RN-futuro"></div>
              </div>

              <div className="RS">
                <div className="img-tooltip">
                  <span>Rio Grande do Sul</span>
                  <img className="img-bandeira" alt="RS" src={require('../assets/Bandeiras/RS.png')} />
                </div>
                <span className="titulo-bandeira">RS</span>
                <div className="alertas-RS-hoje" id="alertas-RS-hoje"></div>
                <div className="alertas-RS-futuro" id="alertas-RS-futuro"></div>
              </div>

              <div className="RO">
                <div className="img-tooltip">
                  <span>Rondônia</span>
                  <img className="img-bandeira" alt="RO" src={require('../assets/Bandeiras/RO.png')} />
                </div>
                <span className="titulo-bandeira">RO</span>
                <div className="alertas-RO-hoje" id="alertas-RO-hoje"></div>
                <div className="alertas-RO-futuro" id="alertas-RO-futuro"></div>
              </div>

              <div className="RR">
                <div className="img-tooltip">
                  <span>Roraima</span>
                  <img className="img-bandeira" alt="RR" src={require('../assets/Bandeiras/RR.png')} />
                </div>
                <span className="titulo-bandeira">RR</span>
                <div className="alertas-RR-hoje" id="alertas-RR-hoje"></div>
                <div className="alertas-RR-futuro" id="alertas-RR-futuro"></div>
              </div>

              <div className="SC">
                <div className="img-tooltip">
                  <span>Santa Catarina</span>
                  <img className="img-bandeira" alt="SC" src={require('../assets/Bandeiras/SC.png')} />
                </div>
                <span className="titulo-bandeira">SC</span>
                <div className="alertas-SC-hoje" id="alertas-SC-hoje"></div>
                <div className="alertas-SC-futuro" id="alertas-SC-futuro"></div>
              </div>

              <div className="SP">
                <div className="img-tooltip">
                  <span>São Paulo</span>
                  <img className="img-bandeira" alt="SP" src={require('../assets/Bandeiras/SP.png')} />
                </div>
                <span className="titulo-bandeira">SP</span>
                <div className="alertas-SP-hoje" id="alertas-SP-hoje"></div>
                <div className="alertas-SP-futuro" id="alertas-SP-futuro"></div>
              </div>

              <div className="SE">
                <div className="img-tooltip">
                  <span>Sergipe</span>
                  <img className="img-bandeira" alt="SE" src={require('../assets/Bandeiras/SE.png')} />
                </div>
                <span className="titulo-bandeira">SE</span>
                <div className="alertas-SE-hoje" id="alertas-SE-hoje"></div>
                <div className="alertas-SE-futuro" id="alertas-SE-futuro"></div>
              </div>

              <div className="TO">
                <div className="img-tooltip">
                  <span>Tocantins</span>
                  <img className="img-bandeira" alt="TO" src={require('../assets/Bandeiras/TO.png')} />
                </div>
                <span className="titulo-bandeira">TO</span>
                <div className="alertas-TO-hoje" id="alertas-TO-hoje"></div>
                <div className="alertas-TO-futuro" id="alertas-TO-futuro"></div>
              </div>
            </div>
          </div>
        </div>

        <Legenda />
      </div>
      <Footer />
    </div>
  );
}

export default Index;
