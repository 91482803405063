import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import '../pages/css/Style.css'
import '../pages/css/Mobile.css'
import '../pages/css/HD-Screen.css'

const Legenda = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClickOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  return (
    <div className="container-legenda">
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Legenda</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <table id="tabela-legenda">
              <tr>
                <th>Ícone</th>
                <th>Descrição</th>
              </tr>

              <tr>
                <td><img className="img-cor" alt="Perigo Potencial" src={require('../assets/Placas/Cor/Amarelo.png')} /></td>
                <td>Perigo Potencial. Situação meteorológica potencialmente perigosa. Cuidado na prática de atividades sujeitas a riscos de caráter meteorológico. Mantenha-se informado sobre as condições meteorológicas previstas e não corra risco desnecessário.</td>
              </tr>

              <tr>
                <td><img className="img-cor" alt="Perigo" src={require('../assets/Placas/Cor/Laranja.png')} /></td>
                <td>Perigo. Situação meteorológica perigosa. Mantenha-se muito vigilante e informe-se regularmente sobre as condições meteorológicas previstas. Inteire-se sobre os riscos que possam ser inevitáveis. Siga os conselhos das autoridades.</td>
              </tr>

              <tr>
                <td><img className="img-cor" alt="Grande Perigo" src={require('../assets/Placas/Cor/Vermelho.png')} /></td>
                <td>Grande Perigo. Situação meteorológica de grande perigo. Estão previstos fenômenos meteorológicos de intensidade excepcional. Grande probabilidade de ocorrência de grandes danos e acidentes, com riscos para a integridade física ou mesmo à vida humana. Mantenha-se informado sobre as condições meteorológicas previstas e os possíveis riscos. Siga as instruções e conselhos das autoridades em todas as circunstâncias e prepare-se para medidas de emergência.</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Ventos_Costeiros.png')} alt="Ventos Costeiros" /></td>
                <td>Ventos Costeiros</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Ressaca.png')} alt="Ressaca" /></td>
                <td>Ressaca</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Onda_de_Frio.png')} alt="Onda de Frio" /></td>
                <td>Onda de Frio</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Tornados.png')} alt="Tornados" /></td>
                <td>Tornados</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Tempestade_de_Raios.png')} alt="Tempestade de Raios" /></td>
                <td>Tempestade de Raios</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Granizo.png')} alt="Granizo" /></td>
                <td>Granizo</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Chuvas_Intensas.png')} alt="Chuvas Intensas" /></td>
                <td>Chuvas Intensas</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Vendaval.png')} alt="Vendaval" /></td>
                <td>Vendaval</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Onda_de_Calor.png')} alt="Onda de Calor" /></td>
                <td>Onda de Calor</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Friagem.png')} alt="Friagem" /></td>
                <td>Friagem</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Geada.png')} alt="Geada" /></td>
                <td>Geada</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Estiagem.png')} alt="Estiagem" /></td>
                <td>Estiagem</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Seca.png')} alt="Seca" /></td>
                <td>Seca</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Incendios.png')} alt="Incêndios" /></td>
                <td>Incêndios</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Baixa_Umidade.png')} alt="Baixa Umidade" /></td>
                <td>Baixa Umidade</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Neve.png')} alt="Neve" /></td>
                <td>Neve</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Declinio_de_Temperatura.png')} alt="Declínio de Temperatura" /></td>
                <td>Declínio de Temperatura</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Acumulado_de_Chuva.png')} alt="Acumulado de Chuva" /></td>
                <td>Acumulado de Chuva</td>
              </tr>

              <tr>
                <td><img className="img-desenho" src={require('../assets/Placas/Desenho/Tempestade.png')} alt="Tempestade" /></td>
                <td>Tempestade</td>
              </tr>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <span className="titulo-legenda">Legenda</span><br /><br />
      <div className="legenda-cores">
        <div className="img-tooltip">
          <img className="img-cor" alt="Perigo Potencial" src={require('../assets/Placas/Cor/Amarelo.png')} title="Perigo Potencial" onClick={handleClickOpen} />
          <span>Perigo Potencial</span>
        </div>
        <div className="img-tooltip">
          <img className="img-cor" alt="Perigo" src={require('../assets/Placas/Cor/Laranja.png')} title="Perigo" onClick={handleClickOpen} />
          <span>Perigo</span>
        </div>

        <div className="img-tooltip">
          <span>Grande Perigo</span>
          <img className="img-cor" alt="Grande Perigo" src={require('../assets/Placas/Cor/Vermelho.png')} title="Grande Perigo" onClick={handleClickOpen} />
        </div>
      </div>
  
      <div className="legenda-desenhos">
        <div className="img-tooltip">
          <span>Ventos Costeiros</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Ventos_Costeiros.png')} alt="Ventos Costeiros" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Ressaca</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Ressaca.png')} alt="Ressaca" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Onda de Frio</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Onda_de_Frio.png')} alt="Onda de Frio" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Tornados</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Tornados.png')} alt="Tornados" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Tempestade de Raios</span>        
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Tempestade_de_Raios.png')} alt="Tempestade de Raios" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Granizo</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Granizo.png')} alt="Granizo" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Chuvas Intensas</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Chuvas_Intensas.png')} alt="Chuvas Intensas" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Vendaval</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Vendaval.png')} alt="Vendaval" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Onda de Calor</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Onda_de_Calor.png')} alt="Onda de Calor" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Friagem</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Friagem.png')} alt="Friagem" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Geada</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Geada.png')} alt="Geada" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Estiagem</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Estiagem.png')} alt="Estiagem" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Seca</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Seca.png')} alt="Seca" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Incêndios</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Incendios.png')} alt="Incêndios" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Baixa Umidade</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Baixa_Umidade.png')} alt="Baixa Umidade" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Neve</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Neve.png')} alt="Neve" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Declínio de Temperatura</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Declinio_de_Temperatura.png')} alt="Declínio de Temperatura" onClick={handleClickOpen} />
        </div>
        
        <div className="img-tooltip">
          <span>Acumulado de Chuva</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Acumulado_de_Chuva.png')} alt="Acumulado de Chuva" onClick={handleClickOpen} />
        </div>

        <div className="img-tooltip">
          <span>Tempestade</span>
          <img className="img-desenho" src={require('../assets/Placas/Desenho/Tempestade.png')} alt="Tempestade" onClick={handleClickOpen} />
        </div>
      </div>
    </div>
  )
}

export default Legenda;
