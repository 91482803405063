import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Index from './pages/Index';
import Show from './pages/Show';
import Rss from './pages/Rss';
import Mapa from './pages/Mapa';

export default function Routes(){
    return (
        <BrowserRouter>
            <Route path='/' exact component={Index} />
            <Route path='/:id' exact component={Show} />
            <Route path='/avisos/mapa/iframe/portal' exact component={Mapa} />
            <Route path='/avisos/rss/:id?' exact component={Rss} />
        </BrowserRouter>
    );
}