import React, { useRef, useEffect, useState } from 'react'
import $ from 'jquery';
import ApiPrevmet from '../services/Prevmet.js'
import { Map, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import JsonEstados from '../assets/json/estados.json'

import './css/Style.css'
import './css/Mobile.css'
import './css/HD-Screen.css'
import 'leaflet/dist/leaflet.css'

const Mapa = () => {
  const mapa = useRef(React.createRef)

  const [avisosHoje, setAvisosHoje] = useState(null)
  const [iconesHoje, setIconesHoje] = useState(null)
  const [avisosFuturo, setAvisosFuturo] = useState(null)
  const [iconesFuturo, setIconesFuturo] = useState(null)

  const getAvisos = async () => {
    await ApiPrevmet.get('/avisos/ativos')
    .then(res => {
      let hoje = []
      let icones_hoje = []
      let futuro = []
      let icones_futuro = []

      for(let i = 0; i < res.data.hoje.length; i++) {
        L.geoJson(JSON.parse(res.data.hoje[i].poligono), {
          onEachFeature: function (feature, layer) {
            layer.options.fillColor = res.data.hoje[i].aviso_cor
            layer.options.fillOpacity = 0.7
            layer.options.color = res.data.hoje[i].aviso_cor
            layer.options.opacity = 1
            layer.options.weight = 1
            hoje.push(layer)

            icones_hoje.push(
              L.marker(
                [layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
                  icon: L.icon({
                    iconUrl     : res.data.hoje[i].icone,
                    iconAnchor  :   [17, 50],
                    popupAnchor :  [2, -40]
                  })
            }).bindPopup(`<b>${res.data.hoje[i].descricao}</b><br />Para mais informações <a href="/${res.data.hoje[i].id}" target="_blank">clique aqui</a>.`))
          }
        })
      }

      for(let i = 0; i < res.data.futuro.length; i++) {
        L.geoJson(JSON.parse(res.data.futuro[i].poligono), {
          onEachFeature: function (feature, layer) {
            layer.options.fillColor = res.data.futuro[i].aviso_cor
            layer.options.fillOpacity = 0.7
            layer.options.color = res.data.futuro[i].aviso_cor
            layer.options.opacity = 1
            layer.options.weight = 1
            futuro.push(layer)

            icones_futuro.push(
              L.marker(
                [layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng], {
                  icon: L.icon({
                    iconUrl     : res.data.futuro[i].icone,
                    iconAnchor  :   [17, 50],
                    popupAnchor :  [2, -40]
                  })
            }).bindPopup(`<b>${res.data.futuro[i].descricao}</b><br />Para mais informações <a href="/${res.data.futuro[i].id}" target="_blank">clique aqui</a>.`))
          }
        })
      }
      setIconesHoje(L.layerGroup(icones_hoje))
      setIconesFuturo(L.layerGroup(icones_futuro))

      setAvisosHoje(L.layerGroup(hoje))
      setAvisosFuturo(L.layerGroup(futuro))
    })
    .catch((err) => {
      console.log(err)
      alert('Erro ao carregar avisos, tente novamente. ')
    })
  }

  const adicionarControles = async () => {
    const checbox = L.control({ position: 'topright' })

    let options = `
      <div id='id-avisos-checks'>
        <input type='radio' name='avisos-checks' value='hoje' checked />Hoje <br />
        <input type='radio' name='avisos-checks' value='futuro' />Futuro
      </div>
    `

    checbox.onAdd = function() {
      let div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = `
        <div class='leaflet-control-layers-expanded leaflet-control-layers style' id='checbox-menu-right'>
          ${options}
        </div>`;
      return div;
    }
    checbox.addTo(mapa.current.leafletElement)

    const legenda = L.control({ position: 'topleft' })
    legenda.onAdd = function() {
      let div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = `
        <div class='leaflet-control-layers-expanded leaflet-control-layers style' id='id-legenda-left'>
          <div style="display: flex;"><div style="width: 20px; height: 20px; background-color: #FFFE00; margin-right: 10px; margin-bottom: 5px;"></div>Perigo Potencial</div>
          <div style="display: flex;"><div style="width: 20px; height: 20px; background-color: #F96602; margin-right: 10px; margin-bottom: 5px;"></div>Perigo</div>
          <div style="display: flex;"><div style="width: 20px; height: 20px; background-color: #F80703; margin-right: 10px;"></div>Grande Perigo</div>
        </div>`;
      return div;
    }
    legenda.addTo(mapa.current.leafletElement)

    const plus = L.control({ position: 'bottomright' })
    plus.onAdd = function() {
      let div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = `
        <div id='id-plus-right'>
          <a href="https://alertas2.inmet.gov.br" target="_blank">
            <img src='${require('../assets/img_mais.png')}' />
          </a>
        </div>`;
      return div;
    }
    plus.addTo(mapa.current.leafletElement)
  }

  const adicionarMarcaDagua = async () => {
    mapa.current.leafletElement.addLayer(L.imageOverlay(
      'https://portal.inmet.gov.br/uploads/icones/logosfundo.png',
      [[7.15, -75.4],[-35.7, -31.5]],
      { opacity: 0.8 }
    ))
  }

  const inicializarZoomMapa = () => {
    const resolucao = parseInt((window.screen.availWidth / 20))
    let zoom = 2 + 0.06 * resolucao;
    if (zoom > 4.5) {
        zoom = 4.2
    }
    mapa.current.leafletElement.setZoom(zoom)
  }

  useEffect(() => {
    adicionarControles()
    getAvisos()
    adicionarMarcaDagua()
    
    mapa.current.leafletElement.addLayer(L.geoJson(JsonEstados, {
      color: 'black',
      fillColor: '#E5ECC3',
      weight: 1.1,
      fillOpacity: 1,
    }))
  }, [])

  useEffect(() => {
    inicializarZoomMapa()
    if(avisosHoje !== null && avisosFuturo !== null) {
      mapa.current.leafletElement.addLayer(avisosHoje)
      mapa.current.leafletElement.addLayer(iconesHoje)

      $('input[name=avisos-checks]').change(function (){
        mapa.current.leafletElement.removeLayer(avisosHoje)
        mapa.current.leafletElement.removeLayer(avisosFuturo)
        mapa.current.leafletElement.removeLayer(iconesHoje)
        mapa.current.leafletElement.removeLayer(iconesFuturo)
        if($(this).val() === 'hoje') {
          mapa.current.leafletElement.addLayer(avisosHoje)
          mapa.current.leafletElement.addLayer(iconesHoje)
        } else if($(this).val() === 'futuro') {
          mapa.current.leafletElement.addLayer(avisosFuturo)
          mapa.current.leafletElement.addLayer(iconesFuturo)
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avisosHoje, avisosFuturo])

  return (
    <div>
      <Map
        ref={mapa}
        className={'map-iframe'}
        style={{ width: '100vw', height: '100vh' }}
        zoom={4}
        scrollWheelZoom={false}
        center={[-15.6014, -59.597]}
        preferCanvas={'true'}
        doubleClickZoom={false}
        zoomControl={false}
        maxBounds={[[7.23542, -95.332414], [-35.67909, -16.362345]]}>
	<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
      </Map>
    </div>
  )
}
export default Mapa;
