import React, { useRef, useEffect, useState } from 'react'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Legenda from '../components/Legenda.js'
import L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet'
import ApiPrevmet from '../services/Prevmet.js'
import JsonEstados from '../assets/json/estados.json'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { MDBDataTable } from 'mdbreact'

import './css/Style.css'
import './css/Mobile.css'
import './css/HD-Screen.css'
import 'leaflet/dist/leaflet.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'


const Show = (props) => {
  const mapa = useRef(React.createRef)
  const [aviso, setAviso] = useState(null)
  const [tabela, setTabela] = useState({})
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  const getAviso = async (id) => {
    await ApiPrevmet.get(`/aviso/getByID/${id}`)
    .then((res) => {
      let temp = []
      setAviso(res.data)
      L.geoJson(JSON.parse(res.data.poligono), {
        onEachFeature: function (feature, layer) {
          layer.options.fillColor = res.data.aviso_cor
          layer.options.fillOpacity = 0.7
          layer.options.color = res.data.aviso_cor
          layer.options.opacity = 1
          layer.options.weight = 1
          temp.push(layer)

          mapa.current.leafletElement.setView(new L.LatLng(layer.getBounds().getCenter().lat, layer.getBounds().getCenter().lng), 6)
        }
      })
      mapa.current.leafletElement.addLayer(L.layerGroup(temp))
    })
  }

  const montarTabela = () => {
    let data = {
      columns : [
        { label : 'Município', field : 'municipio' },
        { label : 'Geocode', field : 'geocode' },
        { label : 'UF', field : 'uf' },
      ],
      rows : []
    }
    let municipios = aviso.municipios.split(',')
    for(let i = 0; i < municipios.length; i++) {
      let municipio = municipios[i].split(' - ')[0]
      let uf = municipios[i].split(' - ')[1].split(' ')[0]
      let geocode = municipios[i].split('(')[1].slice(0, -1)

      data.rows.push({ municipio, uf, geocode})
    }

    setTabela(data)
  }

  useEffect(() => {
    if(aviso) {
      montarTabela()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aviso])

  useEffect(() => {
    mapa.current.leafletElement.addLayer(L.geoJson(JsonEstados, {
      color: 'black',
      fillColor: '#E5ECC3',
      weight: 1.1,
      fillOpacity: 1,
    }))
    getAviso(props.match.params.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <Header />
      <div className="container-geral">
        <div className="container-mapa">
          <Map
            ref={mapa}
            className={'map'}
            style={{ height: '600px', width: '100%', borderRadius: 15 }}
            zoom={3}
            scrollWheelZoom={false}
            minZoom={4}
            center={[-15.6014, -59.597]}
            preferCanvas={'true'}
            doubleClickZoom={false}
            zoomControl={true}
            maxBounds={[[7.23542, -95.332414], [-35.67909, -16.362345]]}>
	    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
          </Map>
        </div>

        <div className="container-descricao-aviso">
          {aviso ?
            <div>
              <font style={{ fontWeight: 'bold' }}>Aviso de:</font> {aviso?.descricao} <br />
              <font style={{ fontWeight: 'bold' }}>Grau de severidade:</font> <font color={aviso?.aviso_cor}><font style={{ fontWeight: 'bold' }}>{aviso?.severidade}</font></font> <br />
              <font style={{ fontWeight: 'bold' }}>Início:</font> {aviso.data_inicio?.split('T')[0].split('-').reverse().join('/')} {aviso.hora_inicio?.split(':')[0]}h{aviso?.hora_inicio.split(':')[1]}min <br />
              <font style={{ fontWeight: 'bold' }}>Fim:</font> {aviso.data_fim?.split('T')[0].split('-').reverse().join('/')} {aviso.hora_fim?.split(':')[0]}h{aviso?.hora_fim.split(':')[1]}min <br />
              <hr />

              <font style={{ fontWeight: 'bold' }}>Riscos Potenciais: </font><br />
              <p style={{ textAlign: 'justify' }}>
                {aviso.riscos.length > 0 ?
                  <div>
                    INMET publica aviso iniciando em: {aviso?.data_inicio.split('T')[0].split('-').reverse().join('/')} {aviso?.hora_inicio}. {aviso.riscos?.map((risco) => risco + '. ')}
                  </div> : <div>Nenhum encontrado.</div>}
              </p>
              <hr />

              <font style={{ fontWeight: 'bold' }}>Instruções: </font>
              <p style={{ textAlign: 'justify' }}>
                {aviso.instrucoes?.length > 0 ?
                  <ul>
                    {aviso.instrucoes.map((instrucao) => <li>{instrucao}</li>)}
                  </ul> : <div>Nenhuma encontrada.</div>}
              </p>
              <hr />

              <font style={{ fontWeight: 'bold' }}>Municípios: </font><br />
              <p style={{ textAlign: 'justify' }}>
                {aviso.municipios?.replace(/,/g, ', ').slice(0, 200)}...
                <Button color="primary" onClick={handleClickOpen}>Veja Mais</Button>
              </p>
              <hr />

              <font style={{ fontWeight: 'bold' }}>Áreas Afetadas: </font><br />
              <p style={{ textAlign: 'justify' }}>
                {aviso.mesorregioes?.replace(/,/g, ', ')}
              </p>
            </div>
          :
            <div>
              <font color="red"><font style={{ fontWeight: 'bold' }}>Alerta não encontrado.</font></font>
            </div>}
        </div>
        <Legenda />
      </div>
      <Footer />

      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
      <DialogTitle id="responsive-dialog-title">Municípios</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MDBDataTable
              responsive
              id='tabela'
              striped
              bordered
              hover
              data={tabela}
              noRecordsFoundLabel="Nenhum Registro encontrado."
              infoLabel={['Mostrando', 'até', 'de', 'entradas']}
              paginationLabel={['Anterior', 'Próximo']}
              searchLabel='Buscar'
              entriesLabel='Mostrar'
              paging={true}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions><Button onClick={handleClose} color="primary">Fechar</Button></DialogActions>
      </Dialog>
    </div>
  )
}

export default Show;
