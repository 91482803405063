import React from 'react'

import '../pages/css/Style.css'
import '../pages/css/Mobile.css'
import '../pages/css/HD-Screen.css'

const Footer = () => {
  return (
    <div className="footer">
      <b>Alert-AS - Centro Virtual para Avisos de Eventos Meteorológicos Severos</b><br /><br />
      Licença de Uso: O conteúdo deste site, poderá ser reproduzido desde que citada a fonte, excetuando os casos especificados em contrário e os conteúdos replicados de outras fontes.<br />
      O INMET não se responsabiliza por eventuais danos que o conteúdo hospedado por terceiros possa causar, sejam estes morais ou materiais.
    </div>
  );
}

export default Footer;
    