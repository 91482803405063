import React, { useEffect, useState } from 'react'
import ApiPrevmet from '../services/Prevmet.js'
import XMLViewer from 'react-xml-viewer'

const Rss = (props) => {
  const [rss, setRss] = useState('')

  const getRss = async () => {
    if(props.match.params.id !== undefined) {
      let url = '/avisos/rss'
      url += `/${props.match.params.id}`
      await ApiPrevmet.get(url).then(res => { setRss(res.data) })
    } else {
      window.location.href = 'https://apiprevmet3.inmet.gov.br/avisos/rss';
    }
  }

  useEffect(() => {
    getRss()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <XMLViewer xml={rss} />
}
export default Rss;