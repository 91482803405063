import React from 'react'
import LogoAlert from '../assets/logo_alert-as.png'
import LogoOmm from '../assets/logo_omm.png'

import '../pages/css/Style.css'
import '../pages/css/Mobile.css'
import '../pages/css/HD-Screen.css'

const Header = () => {
  return (
    <div className="header">
      <div className="logo-alert">
        <a href="/">
          <img src={LogoAlert} alt="Alert-AS" style={{ backgroundColor: 'white', padding: 8, borderRadius: 10 }} />
        </a>
      </div>
      <div className="titulo">
        <div className="titulo-portal"><a href="https://portal.inmet.gov.br">Instituto Nacional de Meteorologia</a></div>
        <div className="titulo-mapa"><a href="https://www.gov.br/agricultura/pt-br">MINISTÉRIO DA AGRICULTURA E PECUÁRIA</a></div>
      </div>
      <div className="logo-omm">
        <a href="https://public.wmo.int/en">
          <img src={LogoOmm} alt="OMM" style={{ backgroundColor: 'white', padding: 8, borderRadius: 10 }} />
        </a>
      </div>
    </div>
  )
}

export default Header;
